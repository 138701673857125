const dataRoadmap = [
    {
        id: 1,
        time: 'Q1 2024',
        list: [
            {
                "text": "Launch live trading feature"
            },
            {
                "text": "Integrate advanced machine learning algorithms"
            },
            {
                "text": "Implement risk management strategies"
            },
            {
                "text": "Conduct final security audits"
            },
        ],
        positon: 'left',
        style: 'normal'
    },
    {
        id: 2,
        time: 'Q4 2023',
        list: [
            {
                "text": "Release backtesting and simulation functionality"
            },
            {
                "text": "Enhance trading strategy optimization"
            },
            {
                "text": "Develop portfolio management features"
            },
            {
                "text": "Optimize trade execution and latency"
            }
        ],
        positon: 'right',
        style: 'normal'
    },
    {
        id: 3,
        time: 'Q3 2023',
        list: [
            {
                "text": "Develop machine learning models for trade prediction"
            },
            {
                "text": "Launch initial alpha testing phase"
            },
            {
                "text": "Implement data collection and preprocessing"
            },
            {
                "text": "Enhance security with multi-factor authentication"
            },

        ],
        positon: 'left'
    },
    {
        id: 4,
        time: 'Q2 2023',
        list: [
            {
                text: 'Develop decentralized governance model'
            },
            {
                text: 'Launch initial coin offering (ICO) platform'
            },
            {
                text: 'Implement token vesting and locking'
            },
            {
                text: 'Enhance security with decentralized identifiers (DIDs)'
            },
        ],
        positon: 'right'
    },
    {
        id: 5,
        time: 'Q1 2023',
        list: [
            {
                "text": "Complete bot core development"
            },
            {
                "text": "Implement basic trading strategies"
            },
            {
                "text": "Launch internal testing phase"
            },
            {
                "text": "Conduct preliminary security audits"
            },
        ],
        positon: 'left'
    },
    {
        id: 6,
        time: 'Q4 2022',
        list: [
            {
                "text": "Conduct market research and analysis"
            },
            {
                "text": "Formulate project roadmap and milestones"
            },
            {
                "text": "Assemble development team"
            },
            {
                "text": "Design user interface and experience (UI/UX)"
            },
        ],
        positon: 'right'
    },
];

export default dataRoadmap;

