import React from 'react';
import dataRoadmap from '../assets/fake-data/data-roadmap';
import PageTitle from '../components/pagetitle';
import RoadMap from '../features/roadmap';
import AITrading from '../features/ai';

function Ai(props) {
    return (
        <div className='page-roadmap'>
            <PageTitle title='Ai Trading' />
            <AITrading title='AI Trading' />

            
        </div>
    );
}

export default Ai;