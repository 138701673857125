const dataFaq = [
    {
        id: 1,
        title: 'How long does it take to set up a trading bot?',
        content: 'It takes less than 10 minutes to get started. It is easy as 1-2-3. Just connect to your exchange via API keys, choose coins you want to trade and set your trading bot parameters or copy them from experienced traders.',
        show: 'show'
    },
    {
        id: 2,
        title: 'How much money do I need to start trading with crypto bot?',
        content: 'Our trading bot does not have any minimum. You can start with just as little as $10. Or try our crypto bot without putting your funds on the line by using a demo trading functionality.',
    },
    {
        id: 3,
        title: 'What crypto pairs are available for bot trading?',
        content: 'Our trading robot supports all the coins that your exchange supports. 100+ coins: Ethereum, Binance coin, Polygon and more.',
    },
    {
        id: 4,
        title: 'Is it safe to trade with your crypto bot?',
        content: 'Safety and security is one of our main priorities. TradeSanta trading robot does not store your funds. It has access to your account on the exchange via API keys to trade only. You are in full control of your funds.        ',
    },
    
]

export default dataFaq;