const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
    },
    {
        id: 7,
        name: 'Staking',
        links: '/stake'
    },
    {
        id: 8,
        name: 'Swap',
        links: '/swap'
    },
    {
        id: 9,
        name: 'Ai Trading',
        links: '/ai'
    },
    {
        id: 2,
        name: 'About',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'About 01',
                links: '/about-v1'
            },
            {
                id: 2,
                sub: 'About 02',
                links: '/about-v2'
            },
            {
                id: 3,
                sub: 'Coming Soon',
                links: '/coming-soon'
            },
            {
                id: 4,
                sub: 'Community',
                links: '/community'
            },
            {
                id: 5,
                sub: 'Team Details',
                links: '/team-details'
            },
            {
                id: 6,
                sub: 'FAQ',
                links: '/faq'
            },
            {
                id: 7,
                sub: '404',
                links: '/404'
            },

        ]
    },
    
    {
        id: 6,
        name: 'Contact',
        links: '/contact'
    },
    
]

export default menus;