import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './styles.scss';

import { useWeb3Modal } from '@web3modal/ethers/react'
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'


const projectId = 'c65135b595b414ac1411537d54b73fe8'


// 1. Get projectId

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com'
}

const polygonNet = {
    chainId: 137,
    name: 'Polygon',
    currency: 'MATIC',
    explorerUrl: 'https://polygonscan.com',
    rpcUrl: 'https://polygon-rpc.com'
  }

  const bscNet = {
    chainId: 56,
    name: 'Polygon',
    currency: 'BNB',
    explorerUrl: 'https://bscscan.com',
    rpcUrl: 'https://bsc-dataseed.binance.org'
  }

// 3. Create a metadata object
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 1 // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet,polygonNet,bscNet],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})


ConnectButton.propTypes = {
    title: PropTypes.string,
    path: PropTypes.string,
    onClick: PropTypes.func,
};

// Function to truncate the address
function truncateAddress(address) {
    const start = address.substring(0, 6);
    const end = address.substring(address.length - 4, address.length);
    return start + '...' + end;
}

function ConnectButton(props) {
    const { open } = useWeb3Modal()
    const { address, isConnected } = useWeb3ModalAccount()
    
    return (
        <>
            {isConnected ? (
                <button className="tf-button-st2 btn-effect">
                    <span className="boder-fade"></span>                                     
                    <span className="effect">Connected: {truncateAddress(address)}</span>
                </button>
            ) : (
                <button className="tf-button-st2 btn-effect" onClick={() => open()}>
                    <span className="boder-fade"></span>                                     
                    <span className="effect"> Connect Wallet</span>
                </button>
            )}
        </>
    );
}

export default ConnectButton;
