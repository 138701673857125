const dataWork = [
    {
        id: 1,
        numb: '01',
        title: 'Set up your wallet',
        text: 'Get started by setting up your digital wallet to securely store your NFTs and interact with the blockchain.',
        active: 'active'
    },
    {
        id: 2,
        numb: '02',
        title: 'Buy your collection',
        text: 'Explore and purchase NFTs from various collections and artists to build your digital portfolio.',
    },
    {
        id: 3,
        numb: '03',
        title: "Add your NFT's",
        text: 'Upload your existing NFTs or mint new ones to showcase your creativity and digital assets.',
    },
    {
        id: 4,
        numb: '04',
        title: "Sell Your NFT's",
        text: 'List your NFTs for sale on marketplaces or auction platforms to find interested buyers and monetize your creations.',
    },
];

export default dataWork;
