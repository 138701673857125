import bgImg1 from '../images/bg-1.jpg';
import bgImg2 from '../images/bg-3.jpg';
import bgImg3 from '../images/bg-4.jpg';
import img1 from '../images/itemslider.png';

const dataSlider = [
    {
        id: 1,
        title: 'TURNING DATA INTO DOLLARS',
        desc : 'Grow your portfolio with state-of-the-art AI machine-learning technology',
        bgImg: bgImg1,
        img : img1
    },
    {
        id: 2,
        title: 'TURNING DATA INTO DOLLARS',
        desc : 'Grow your portfolio with state-of-the-art AI machine-learning technology',
        bgImg: bgImg2,
        classAction: 'two'
    },
    {
        id: 3,
        title: 'TURNING DATA INTO DOLLARS',
        desc : 'Grow your portfolio with state-of-the-art AI machine-learning technology',
        bgImg: bgImg3,
        classAction: 'three'
    },
    {
        id: 4,
        title: 'TURNING DATA INTO DOLLARS',
        desc : 'Grow your portfolio with state-of-the-art AI machine-learning technology',
        bgImg: bgImg1,
        img : img1
    },
    {
        id: 5,
        title: 'TURNING DATA INTO DOLLARS',
        desc : 'Grow your portfolio with state-of-the-art AI machine-learning technology',
        bgImg: bgImg2,
        classAction: 'two'
    },

]

export default dataSlider;