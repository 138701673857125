const dataAbout = [
    {
        id: 1,
        title: ' VALUES ',
        desc: 'At Euro Credit Technology we are committed to helping our clients achieve their financial goals, regardless of whether they have $500 to invest or $500,000. In addition, we believe that genuine accessibility for all requires a user-friendly system that absolutely anyone can use. ',
        class: 'active'
    },
    {
        id: 2,
        title: ' INNOVATION ',
        desc: 'We are constantly pushing the envelope, adopting new innovative technologies, so we can continuously improve the speed and efficiency of our algorithmic trading bot and enhance our clients’ long-term profit potential.',
    },
    {
        id: 3,
        title: ' SECURITY ',
        desc: 'Security is key to our clients’ peace of mind, and we have gone the extra mile to safeguard the privacy and integrity of our clients’ accounts, with tough technological protocols. Our clients deserve the best tools for trading crypto, commodities, forex, stocks and bonds. ',
    },
]

export default dataAbout;