import React, { useState } from 'react';
import './styles.scss';

const tradingSignals = [
  { id: 1, action: 'buy', asset: 'BTC', probability: 0.85, description: 'Strong upward trend expected based on market analysis.', timestamp: '2024-06-01T10:00:00Z' },
  { id: 2, action: 'sell', asset: 'ETH', probability: 0.75, description: 'Possible correction due to overbought conditions.', timestamp: '2024-06-01T11:00:00Z' },
  { id: 3, action: 'hold', asset: 'ADA', probability: 0.65, description: 'Market showing signs of consolidation.', timestamp: '2024-06-01T12:00:00Z' },
  { id: 4, action: 'buy', asset: 'SOL', probability: 0.78, description: 'Strong fundamentals and positive sentiment.', timestamp: '2024-06-01T13:00:00Z' },
  { id: 5, action: 'sell', asset: 'XRP', probability: 0.72, description: 'Anticipated decline due to regulatory concerns.', timestamp: '2024-06-01T14:00:00Z' },
];

const availableTrades = [
  { id: 1, asset: 'BTC', amount: 0.5, description: 'Bitcoin trade based on recent bullish trend.', currentPrice: 35000 },
  { id: 2, asset: 'ETH', amount: 2, description: 'Ethereum trade with moderate risk.', currentPrice: 2500 },
  { id: 3, asset: 'ADA', amount: 1000, description: 'Cardano trade, suitable for long-term holding.', currentPrice: 1.2 },
  { id: 4, asset: 'SOL', amount: 50, description: 'Solana trade, expected short-term gains.', currentPrice: 40 },
  { id: 5, asset: 'XRP', amount: 500, description: 'Ripple trade, high volatility.', currentPrice: 0.8 },
];

const AITrading = () => {
  const [selectedTrade, setSelectedTrade] = useState(null);

  const handleTradeSelection = (event) => {
    const tradeId = parseInt(event.target.value);
    const trade = availableTrades.find(t => t.id === tradeId);
    setSelectedTrade(trade);
  };

  return (
    <section className="tf-section tf-about">
            <div className="container">
                <div className="row">
        <div className="ai-trading">
        <h2>AI Trading Signals</h2>
        <div className="trading-form">
            <label htmlFor="trades">Select Trade:</label>
            <select id="trades" onChange={handleTradeSelection}>
            <option value="">Select a trade</option>
            {availableTrades.map(trade => (
                <option key={trade.id} value={trade.id}>{trade.asset} - {trade.amount} units</option>
            ))}
            </select>
            {selectedTrade && (
            <div className="trade-details">
                <h3>Trade Details</h3>
                <p>Asset: {selectedTrade.asset}</p>
                <p>Amount: {selectedTrade.amount}</p>
                <p>Description: {selectedTrade.description}</p>
                <p>Current Price: ${selectedTrade.currentPrice}</p>
            </div>
            )}
            <button>Execute Trade</button>
        </div>
        <div className="trades">
            <h3>Recent Trading Signals</h3>
            <ul>
            {tradingSignals.map(signal => (
                <li key={signal.id}>
                <p><strong>Action:</strong> {signal.action}</p>
                <p><strong>Asset:</strong> {signal.asset}</p>
                <p><strong>Probability:</strong> {signal.probability}</p>
                <p><strong>Description:</strong> {signal.description}</p>
                <p><strong>Timestamp:</strong> {signal.timestamp}</p>
                </li>
            ))}
            </ul>
        </div>
        </div>
    </div>
    </div>
    </section>
  );
};

export default AITrading;
